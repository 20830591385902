.alloysLoremIpsum,
.dieCastingResources {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: var(--h3-size);
    line-height: 48px;
    font-weight: 600;
    font-family: var(--standard-font);
}

.navigationWrapper {
    top: -40px;
    position: relative;
    z-index: 200;
    width: fit-content;
    margin-left: auto;
}

.sentencesAboutCompany,
.viewAll,
.beyondTheBasics {
    position: relative;
    font-size: var(--button-size);
    align-self: stretch;
    line-height: 24px;
}

.viewAll {
    flex: 1;
    line-height: 20px;
    font-weight: 500;
    color: var(--white);
    text-align: center;
    display: inline-block;
    min-width: 101px;
}

.case-study-grid-swiper .swiper-wrapper {
    transition-timing-function: ease-in-out !important;
}

.chevronRightSmall,
.chevronLeftLarge,
.chevronRightLarge {
    height: 10px;
    width: 5px;
    position: relative;
    object-fit: contain;
    flex-shrink: 0;
}

.chevronLeftLarge,
.chevronRightLarge {
    height: 18.5px;
    width: 20px;
    overflow: hidden;
}

.navigationWrapper,
.navigation {
    display: flex;
    justify-content: flex-end;
    gap: var(--base);
    padding-top: var(--base);
    text-align: right;
}

.button,
.content,
.contentGroup,
.paginationButton,
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentGroup,
.grid,
.container {
    gap: var(--xs);
}

.carousel {
    width: 100%;
}

.contentCarousel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: var(--2xl);
    height: 20.375rem;
}

.contentText {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.descriptionCarousel {
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    margin-top: 1rem;
}

.contentText h3 {
    color: #FFF;
    font-size: 2.4375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3rem; /* 123.077% */
}

.contentText a:last-child {
    margin-top: auto;
}

.contentImg {
    flex: 1;
    display: flex;
    justify-content: center;
}

    .contentImg img {
        width: 100%;
        height: 20.375rem;
        object-fit: cover;
    }

.button,
.content {
    flex-direction: column;
    box-sizing: border-box;
}

.button {
    cursor: pointer;
    border: 1px solid var(--primary-500);
    padding: 9px 67px;
    background-color: var(--primary-500);
    width: 250px;
    border-radius: var(--md);
    align-items: center;
    min-width: 250px;
}

.content {
    align-items: flex-start;
    padding: var(--padding-base) 0 var(--padding-base) var(--xl);
    gap: var(--md);
    max-width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: var(--xl2);
    text-align: left;
    color: var(--charcoal-500);
    font-family: var(--standard-font);
    background: linear-gradient(50deg, #000 8.57%, #37353C 94.33%);
    padding: var(--3xl) var(--5xl);
    width: 100%;
}

.grid {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 1px;
    min-width: 0;
    flex-wrap: wrap;
}

.paginationButton,
.paginationButton1 {
    flex-direction: column;
}

.paginationDotIcon,
.paginationDotIcon1,
.paginationDotIcon2,
.paginationDotIcon3 {
    height: 12px;
    width: 12px;
    position: relative;
    min-height: 12px;
}

.dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--base);
}

.paginationWrapper,
.resourceGrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    max-width: 100%;
    padding: 0 var(--xl);
}

.slide {
    width: 318px !important;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: left;
    align-items: center;
    flex: 0 0 auto;
    margin-right: 20px;
}

.prevButton,
.nextButton {
    width: 46px;
    height: 46px;
    border-radius: var(--br-5xl);
    border: 1px solid var(--charcoal-500);
    padding: var(--padding-sm-8) var(--padding-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    background: white;
}

    .prevButton:hover,
    .nextButton:hover,
    .prevButton[disabled],
    .nextButton[disabled] {
        background: var(--charcoal-500);
        --chevron-color: white;
    }

    .prevButton[disabled],
    .nextButton[disabled] {
        pointer-events: none;
    }

.resourceGridTitle,
.title,
.cards,
.blogGrid {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
    font-family: var(--standard-font);
}

.resourceGridTitle {
    height: 47px;
    flex-shrink: 0;
    font-size: var(--font-size-12xl);
    font-weight: 500;
    line-height: 1.875rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
}

.blogGridContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--2xl);
}

.blogGridHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--md);
}

.blogGridHeading .blogGridEyebrow {
    font-size: var(--paragraphs-size);
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1em;
    margin: 0;
    text-align: center;
    color: white;
}

.blogGridHeading .blogGridTitle {
    font-size: var(--h3-size);
    font-weight: 600;
    line-height: 48px;
    margin: 0;
    text-align: center;
    color: white;
}

.resourceCarouselTitle {
    font-size: 39px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 123.077% */
}

@media screen and (max-width: 1200px) {

    .container {
        flex-wrap: wrap;
        padding: var(--xl2);
    }

    .blogGrid,
    .blogGrid .cards {
        width: auto;
        max-width: 1048px;
    }

    .resourceGrid.overviewGrid,
    .resourceGrid.caseStudyGrid {
        padding: var(--xl) 0;
    }

    .navigationWrapper {
        left: -550px;
    }

    .prevButton:hover,
    .nextButton:hover {
        background: var(--white);
        --chevron-color: var(--charcoal-500);
    }

    .contentText {
        gap: var(--md);
    }
}

@media screen and (max-width: 1024px) {
    .resourceGrid {
        padding: var(--xl) var(--base);
    }
    .alloysLoremIpsum,
    .dieCastingResources {
        font-size: var(--font-size-12xl);
        line-height: 38px;
    }

    .blogGridHeading .blogGridTitle {
        font-size: var(--font-size-12xl);
        font-weight: 500;
        line-height: 30px;
    }

    .contentCarousel {
        flex-direction: column-reverse;
        justify-content: center;
        height: 100%;
        gap: var(--md);
    }

    .content {
        width: 400px;
        padding-left: 0;
    }

    .navigationWrapper {
        left: -350px;
    }
}

@media screen and (max-width: 992px) {
    .resourceGridContainer {
        flex-direction: column;
        align-items: flex-start;
    }

    .resourceGridContainer .grid {
        justify-content: flex-start;
    }

    .resourceGridContainer .content,
    .resourceGridContainer .navigationWrapper {
        padding-left: var(--base);
        margin: 0;
    }


    .blogGridContainer,
    .blogGrid {
        gap: var(--md);
    }

    .blogGrid .cards {
        grid-template-columns: repeat(1, minmax(20rem, 30.5rem));
    }

    .cards {
        justify-content: center;
        grid-template-columns: repeat(1, minmax(20.5rem, 20.5rem));
    }

    .navigationWrapper {
        top: -70px;
        left: -150px;
    }
}

@media screen and (max-width: 768px) {
    .grid,
    .cards {
        justify-content: left;
        grid-template-columns: minmax(246px, 1fr);
    }

    .resourceCarouselTitle {
        font-size: var(--font-size-12xl);
        font-weight: 500;
        line-height: 30px;
    }

    .navigationWrapper {
        margin-left: var(--base);
    }

    .alloysLoremIpsum,
    .dieCastingResources {
        font-size: 23px;
        line-height: 29px;
    }

    .content {
        padding-left: var(--base);
        min-width: 100%;
    }

    .contentImg img {
        width: 100%;
        height: 100%;
        min-height: 278px;
    }

    .paginationWrapper {
        padding-left: var(--xl);
        padding-right: var(--xl);
    }

    .navigationWrapper {
        left: 0px;
        top: 0;
        justify-content: flex-start;
        margin: 0;
    }
}

@media screen and (max-width: 640px) {
    .container {
        padding: var(--base);
    }

    .navigationWrapper {
        gap: var(--xs);
    }
}
